<template>
  <div class="container">
    <el-row :gutter="20">
      <el-col :span="6">
        <div>
          <el-card>
            <div slot="header" class="headinitCard">
              <span><i class="el-icon-user-solid" style="margin:5px;"></i>全体教职工数量</span>
            </div>
            <div class="initCard">
              共 {{ initData.allteacher }} 人
            </div>
          </el-card>
        </div>
      </el-col>
      <el-col :span="6">
        <div>
          <el-card>
            <div slot="header" class="headinitCard">
              <span><i class="el-icon-user" style="margin:5px;"></i>全体学生数量</span>
            </div>
            <div class="initCard">
              共 {{ initData.allstudent }} 人
            </div>
          </el-card>
        </div>
      </el-col>
      <el-col :span="6">
        <div>
          <el-card>
            <div slot="header" class="headinitCard">
              <span><i class="el-icon-male" style="margin:5px;"></i>男学生比例</span>
            </div>
            <div class="initCard">
              共 {{ initData.studentMan }} %
            </div>
          </el-card>
        </div>
      </el-col>
      <el-col :span="6">
        <div>
          <el-card>
            <div slot="header" class="headinitCard">
              <span><i class="el-icon-female" style="margin:5px;"></i>女学生比例</span>
            </div>
            <div class="initCard">
              共 {{ initData.studentWoman }} %
            </div>
          </el-card>
        </div>
      </el-col>
    </el-row>
    <el-card style="margin-top: 20px;">
      <div slot="header" style="display: flex;">
        <div style="margin-right: 20px;">
          <i class="el-icon-location" style="margin-right: 5px; color: cornflowerblue;"></i>
          <span class="defaultfont" style="margin-right: 5px;">选择班级:</span>
          <el-select v-model="NowClass" placeholder="请选择">
            <el-option v-for="item in allClass" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div>
          <i class="el-icon-stopwatch" style="margin-right: 5px; color: cornflowerblue;"></i>
          <span class="defaultfont" style="margin-right: 5px;">学期选择:</span>
          <el-select v-model="leftSemester" placeholder="请选择">
            <el-option v-for="item in allSemester" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
          <i class="el-icon-s-promotion" style="margin-right: 5px; margin-left: 5px; color: cornflowerblue;"></i>
          <el-select v-model="rightSemester" placeholder="请选择" style="margin-right: 15px;">
            <el-option v-for="item in allSemester" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
          <el-button type="primary" round @click="clickTabButton">确定</el-button>
        </div>
      </div>
      <div>
        <el-tabs v-model="initCharts" type="card" @tab-click="handleClick">
          <el-tab-pane label="品德发展" name="A">
          </el-tab-pane>
          <el-tab-pane label="学业发展" name="B">
          </el-tab-pane>
          <el-tab-pane label="身心发展" name="C">
          </el-tab-pane>
          <el-tab-pane label="审美素养" name="D">
          </el-tab-pane>
          <el-tab-pane label="劳动与社会实践" name="E">
          </el-tab-pane>
          <el-tab-pane label="综合" name="Z">
          </el-tab-pane>
        </el-tabs>
      </div>
      <div ref="mains" style="width: auto;height: 300px"></div>

    </el-card>
  </div>
</template>

<script>
import { AllSchoolTeacher, AllStudent, AllSemester, GetAllClass, GetSemester, GetABCScore } from "../../api/thing"
let defaultTypeList = [
  ["理想信念", "德育知识", "社会责任", "行为习惯"],
  ["学习习惯", "创新精神", "学业水平"],
  ["健康生活", "身心素质"],
  ["美育实践", "感受表达"],
  ["劳动习惯", "社会体验"],
  ["品德发展", "学业发展", "身心发展", "审美素养", "劳动与社会实践"]
];
export default {
  name: "",
  data() {
    return {
      initData: {
        allteacher: 0,
        allstudent: 0,
        studentMan: 0,
        studentWoman: 0,
      },
      allClass: [],
      allSemester: [],
      NowClass: 1,
      leftSemester: 0,
      rightSemester: 0,
      initCharts: "A",
      chartInstance: null,
      AllTypeLeft: [],
      AllTypeRight: [],
      A: [],
      source: [],
    };
  },
  created() {
    this.intoWebSchool()
  },
  mounted() {
  },
  methods: {
    async intoWebSchool() {
      try {
        await AllSemester().then(val => {
          this.allSemester = val.rows;
          GetSemester().then(value => {
            this.leftSemester = value.last.id;
            this.rightSemester = value.now.id;
            this.source[0] = ['学期', this.allSemester[this.leftSemester - 1].name, this.allSemester[this.rightSemester - 1].name]
          });
        });

        await GetAllClass().then(val => {
          this.allClass = val.rows;
        });

        AllSchoolTeacher().then(value => {
          this.initData.allteacher = value.data;
        });

        AllStudent().then(val => {
          this.initData.allstudent = parseInt(val.data[0].number) + parseInt(val.data[1].number);
          this.initData.studentWoman = val.data[1].percentage;
          this.initData.studentMan = val.data[0].percentage;
        });
        this.queryABCScore(this.initCharts);
      } catch (error) {
        console.error('错误:', error);
      }
    },
    handleClick(tab) {
      this.resetSource();
      this.queryABCScore(this.initCharts);
    },
    getBL(index, legend) {
      const promises = [];
      for (let i = index; i <= legend; i++) {
        promises.push(GetABCScore(this.NowClass, this.leftSemester, "B", i).then(val => {
          this.AllTypeLeft[val.rateId - 1] = val;
        }));
      }

      return Promise.all(promises);
    },

    getBR(index, legend) {
      const promises = [];
      for (let i = index; i <= legend; i++) {
        promises.push(GetABCScore(this.NowClass, this.rightSemester, "B", i).then(val => {
          this.AllTypeRight[val.rateId - 1] = val;
        }));
      }

      return Promise.all(promises);
    },

    getAL(index, legend) {
      const promises = [];
      for (let i = index; i <= legend; i++) {
        promises.push(GetABCScore(this.NowClass, this.leftSemester, "A", i).then(val => {
          this.AllTypeLeft[val.rateId - 1] = val;
        }));
      }

      return Promise.all(promises);
    },

    getAR(index, legend) {
      const promises = [];
      for (let i = index; i <= legend; i++) {
        promises.push(GetABCScore(this.NowClass, this.rightSemester, "A", i).then(val => {
          this.AllTypeRight[val.rateId - 1] = val;
        }));
      }

      return Promise.all(promises);
    },
    defaultOptions() {
      const option = {
        legend: {},
        tooltip: {
        },
        dataset: {
          source: this.source
        },
        xAxis: { type: 'category' },
        yAxis: {},
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [{
          type: 'bar',
          barWidth: '20%',
          label: {
            show: true, //开启显示
            position: 'top', //在上方显示
            formatter: function (name) {
              return name.data[1] + "分";
            },
            textStyle: { //数值样式
              color: 'black',//字体颜色
              fontSize: 10//字体大小
            },
          },
          itemStyle: {
            normal: {
              color: function (val) {
                if (val.data[2] < val.data[1]) {
                  if (["理想信念", "德育知识", "社会责任", "行为习惯"].includes(val.data[0])) {
                    return "#0c8edb";
                  } else if (["学习习惯", "创新精神", "学业水平"].includes(val.data[0])) {
                    return "#955ee2";
                  } else if (["健康生活", "身心素质"].includes(val.data[0])) {
                    return "#5acd8c";
                  } else if (["美育实践", "感受表达"].includes(val.data[0])) {
                    return "#fd4346";
                  } else if (["劳动习惯", "社会体验"].includes(val.data[0])) {
                    return "#ea9a6e";
                  } else if (["品德发展", "学业发展", "身心发展", "审美素质", "劳动与社会实践"].includes(val.data[0])) {
                    switch (val.data[0]) {
                      case "品德发展":
                        return "#0c8edb";
                      case "学业发展":
                        return "#955ee2";
                      case "身心发展":
                        return "#5acd8c";
                      case "审美素养":
                        return "#fd4346";
                      case "劳动与社会实践":
                        return "#ea9a6e";
                      default:
                        return "#fd4346"; // 默认返回黑色或其他颜色
                    }
                  }
                } else {
                  return '#FF6666'
                }
              },
            }
          }
        }, {
          type: 'bar',
          barWidth: '20%',
          label: {
            show: true, //开启显示
            position: 'top', //在上方显示
            formatter: function (name) {
              return name.data[2] + "分";
            },
            textStyle: { //数值样式
              color: 'black',//字体颜色
              fontSize: 10//字体大小
            },
          },
          itemStyle: {
            normal: {
              color: function (val) {
                if (val.data[2] > val.data[1]) {
                  if (["理想信念", "德育知识", "社会责任", "行为习惯"].includes(val.data[0])) {
                    return "#0c8edb";
                  } else if (["学习习惯", "创新精神", "学业水平"].includes(val.data[0])) {
                    return "#955ee2";
                  } else if (["健康生活", "身心素质"].includes(val.data[0])) {
                    return "#5acd8c";
                  } else if (["美育实践", "感受表达"].includes(val.data[0])) {
                    return "#fd4346";
                  } else if (["劳动习惯", "社会体验"].includes(val.data[0])) {
                    return "#ea9a6e";
                  } else if (["品德发展", "学业发展", "身心发展", "审美素质", "劳动与社会实践"].includes(val.data[0])) {
                    switch (val.data[0]) {
                      case "品德发展":
                        return "#0c8edb";
                      case "学业发展":
                        return "#955ee2";
                      case "身心发展":
                        return "#5acd8c";
                      case "审美素养":
                        return "#fd4346";
                      case "劳动与社会实践":
                        return "#ea9a6e";
                      default:
                        return "#fd4346"; // 默认返回黑色或其他颜色
                    }
                  }
                } else {
                  return '#FF6666'
                }
              },
            }
          }
        }],
        graphic: [
          {
            type: 'group',
            left: 'center',
            bottom: 10,
            children: [
              {
                type: 'text',
                z: 100,
                left: 'center',
                top: 'middle',
                style: {
                  fill: '#333',
                  text: "红色为下降态势，五环色为进步态势",
                  font: '14px Microsoft YaHei'
                }
              }
            ]
          }
        ],
      };

      return option;
    },
    initEcharts(key) {
      let k = 0;
      if (key == "B") {
        k = 10000
      } else if (key == "Z") {
        k = 10000
      }
      else {
        k = 4000
      }
      this.chartInstance = this.$echarts.init(this.$refs.mains);
      this.chartInstance.showLoading();
      setTimeout(() => {
        this.chartInstance.setOption(this.defaultOptions(), true);
        this.chartInstance.hideLoading();
      }, k);
    },
    queryABCScore(key) {
      switch (key) {
        case "A":
          this.getBL(1, 4).then(() => {
            this.getBR(1, 4).then(() => {
              this.mergeArrays(this.AllTypeLeft, this.AllTypeRight);
            });
          });
          break;
        case "B":
          this.getBL(5, 7).then(() => {
            this.getBR(5, 7).then(() => {
              this.mergeArrays(this.AllTypeLeft, this.AllTypeRight);
            });
          });
          break;
        case "C":
          this.getBL(8, 9).then(() => {
            this.getBR(8, 9).then(() => {
              this.mergeArrays(this.AllTypeLeft, this.AllTypeRight);
            });
          });
          break;
        case "D":
          this.getBL(10, 11).then(() => {
            this.getBR(10, 11).then(() => {
              this.mergeArrays(this.AllTypeLeft, this.AllTypeRight);
            });
          });
          break;
        case "E":
          this.getBL(12, 13).then(() => {
            this.getBR(12, 13).then(() => {
              this.mergeArrays(this.AllTypeLeft, this.AllTypeRight);
            });
          });
          break;
        default:
          this.getAL(1, 5).then(() => {
            this.getAR(1, 5).then(() => {
              this.mergeArrays(this.AllTypeLeft, this.AllTypeRight);
            });
          });
          break;
      }
      this.initEcharts(key);
    },
    mergeArrays(arr1, arr2) {
      const map = new Map();
      if (arr1.length == 0) {
        arr2.forEach(obj => {
          map.set(obj.name, [0]);
        });
      } else {
        arr1.forEach(obj => {
          map.set(obj.name, [obj.avScore.toFixed(2)]);
        });
      }

      if (arr2.length == 0) {
        arr1.forEach(obj => {
          if (map.has(obj.name)) {
            map.get(obj.name).push(0);
          } else {
            map.set(obj.name, [0]);
          }
        });
      } else {
        arr2.forEach(obj => {
          if (map.has(obj.name)) {
            map.get(obj.name).push(obj.avScore.toFixed(2));
          } else {
            map.set(obj.name, [obj.avScore.toFixed(2)]);
          }
        });
      }


      Array.from(map, ([name, avScores]) => ([name, ...avScores])).forEach(element => {
        this.source.push(element)
      });

    },
    clickTabButton() {
      this.resetSource();
      this.queryABCScore(this.initCharts);
    },
    resetSource() {
      this.AllTypeLeft = [];
      this.AllTypeRight = [];
      this.source = [];
      this.source.unshift(['学期', this.allSemester[this.leftSemester - 1].name, this.allSemester[this.rightSemester - 1].name])
    },
  },

};
</script>

<style scoped>
.defaultfont {
  font-size: 14px;
}

.card-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.headinitCard {
  text-align: center;
}

.initCard {
  font-size: larger;
  text-align: center;
}
</style>